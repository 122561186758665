import React from "react";
import styled from "styled-components";

const StyledContactName = styled.h2`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 100px;
  color: rgba(51, 51, 51);
  font-family: "Unica One";
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.5vw;
  cursor: default;
  letter-spacing: 3px;
  > span {
    margin: 0 12px;
    :nth-child(2) {
      color: rgba(150, 150, 150);
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    font-size: 5vw;
  }
`;

const ContactName = () => (
  <StyledContactName>
    <span>Front-end developer</span>
    <span>Emilia Flisikowska</span>
  </StyledContactName>
);

export default ContactName;
