import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Linkedin } from "@styled-icons/feather/Linkedin";
import { Facebook } from "@styled-icons/feather/Facebook";
import { Github } from "@styled-icons/feather/Github";
import { isInViewport } from "../../helpers/functions";

const fadeInBottom = keyframes`
{
	0%
	{
		transform: translateY(30px);
	}
	100%
	{
		transform: translateY(0);
		opacity: 1;
	}
}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  animation: ${fadeInBottom} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation-play-state: paused;
  opacity: 0;
`;

const IconWrapper = styled.a`
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 15px;
  margin: 6px;
  transition: 0.5s ease;
  @media (max-width: 280px) {
    margin: 5px;
  }
  svg {
    transition: 0.5s ease;
    color: ${({ theme }) => theme.primary};
    width: 30px;
    height: 30px;
    margin: 10px;
    @media (max-width: 280px) {
      height: 20px;
      width: 20px;
      margin: 10px;
    }
  }
  :hover {
    background-color: ${({ theme }) => theme.primary};
    svg {
      color: rgba(238, 238, 238);
    }
  }
`;

const MediaIcons = () => {
  useEffect(() => {
    document.addEventListener("scroll", function (ev) {
      const MediaIcons = document.getElementById("mediaIcons");

      if (isInViewport(MediaIcons))
        MediaIcons.style.animationPlayState = "running";
    });
  });
  return (
    <StyledContainer id="mediaIcons">
      <IconWrapper
        href="https://www.linkedin.com/in/flisikowska"
        target="_blank"
      >
        <Linkedin alt="Linkedin" />
      </IconWrapper>
      <IconWrapper
        href="https://www.facebook.com/emilia.flisikowska.1"
        target="_blank"
      >
        <Facebook alt="Facebook" />
      </IconWrapper>
      <IconWrapper href="https://github.com/flisikowska" target="_blank">
        <Github alt="Github" />
      </IconWrapper>
    </StyledContainer>
  );
};
export default MediaIcons;
