import React, { useState } from "react";
import styled from "styled-components";
import TechnologyIcons from "../molecules/TechnologyIcons";

const StyledWrapper = styled.div`
  height: fit-content;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  cursor: default;
  padding: 30px;
  @media (max-width: 500px) {
    padding: 50px 15px;
  }
`;

const StyledHeader = styled.h2`
  font-weight: 400;
  font-size: 60px;
  font-family: "Unica One";
  margin: 0 0 15px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 2px 2px 5px ${({ theme }) => theme.black};
  @media (max-width: 700px) {
    font-size: 7vw;
  }
  @media (max-width: 500px) {
    margin: 0 0 30px 0;
    font-size: 9vw;
  }
`;

const StyledDescription = styled.div`
  margin: 15px 0;
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  color: ${({ theme }) => theme.black};
  text-shadow: 2px 2px 5px ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  @media (max-width: 700px) {
    font-size: 3.3vw;
  }
  @media (max-width: 500px) {
    margin: 30px 0;
    font-size: 5vw;
  }
`;

const TechnologiesHeader = styled.h1`
  text-align: center;
  font-size: 30px;
  font-family: "Unica One";
  text-transform: uppercase;
  font-weight: 400;
  color: ${({ theme }) => theme.white};
  text-shadow: 2px 2px 5px ${({ theme }) => theme.black};
  margin: 20px 0 40px 0;
  @media (max-width: 700px) {
    font-size: 4vw;
  }
  @media (max-width: 500px) {
    font-size: 6vw;
  }
`;

const TechnologyName = styled.div`
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: ${(theme) => theme.color};
  font-family: "Unica One";
  font-size: 28px;
  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
    font-size: 5.3vw;
  }
`;

const AboutMeBox = ({ id }) => {
  const [technologyTitle, setTechnologyTitle] = useState("");
  const [technologyColor, setTechnologyColor] = useState("");
  return (
    <StyledWrapper id={id}>
      <StyledHeader>Front-End Developer</StyledHeader>
      <StyledDescription>
        Hi! My name is Emilia Flisikowska. I'm 24 years old. Currently I live in
        Toruń. I'm looking for my first full-time job as a Junior Frontend
        Developer.
      </StyledDescription>
      <TechnologiesHeader>Technologies I work with</TechnologiesHeader>
      <TechnologyIcons
        setTitle={setTechnologyTitle}
        setColor={setTechnologyColor}
      />
      <TechnologyName color={technologyColor}>{technologyTitle}</TechnologyName>
    </StyledWrapper>
  );
};

export default AboutMeBox;
