import React from "react";
import styled from "styled-components";
import { Menu } from "@styled-icons/ionicons-outline/Menu";
import { Close } from "@styled-icons/ionicons-outline/Close";

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const StyledToggle = styled(Menu)`
  display: none;
  color: ${({ theme }) => theme.white};
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 2px;
  @media (max-width: 1000px) {
    display: block;
  }
  @media (max-width: 300px) {
    width: 30px !important;
    height: 30px !important;
  }
`;
const StyledToggleX = styled(Close)`
  display: none;
  color: ${({ theme }) => theme.white};
  @media (max-width: 300px) {
    width: 30px !important;
    height: 30px !important;
  }
`;

const Toggle = ({ callback }) => (
  <IconWrapper>
    <StyledToggle size="35" className="toggle" onClick={callback} />
    <StyledToggleX size="35" className="toggleX" onClick={callback} />
  </IconWrapper>
);

export default Toggle;
