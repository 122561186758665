import React from "react";
import styled from "styled-components";
import TechnologyIcon from "../atoms/TechnologyIcon";
import { ReactLogo } from "@styled-icons/fa-brands/ReactLogo";
import { Css3 } from "@styled-icons/boxicons-logos/Css3";
import { Html5 } from "@styled-icons/boxicons-logos/Html5";
import { JsSquare } from "@styled-icons/fa-brands/JsSquare";
import { Bootstrap } from "@styled-icons/boxicons-logos/Bootstrap";
import { Gatsby } from "@styled-icons/simple-icons/Gatsby";
import { Devices } from "@styled-icons/material/Devices";

const StyledContainer = styled.div`
  width: fit-content;
  margin: auto;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  :nth-child(2) {
    position: relative;
    justify-content: center;
    bottom: 15px;
  }
`;

const TechnologyIcons = ({ setTitle, setColor }) => (
  <StyledContainer>
    <StyledRowContainer>
      <TechnologyIcon
        title="Responsive Web Design"
        color="rgba(103, 224, 154)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <Devices />
      </TechnologyIcon>
      <TechnologyIcon
        title="React"
        color="rgba(97, 218, 251)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <ReactLogo />
      </TechnologyIcon>
      <TechnologyIcon
        title="CSS"
        color="rgba(0, 112, 186)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <Css3 />
      </TechnologyIcon>
      <TechnologyIcon
        title="HTML"
        color="rgba(255, 141, 3)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <Html5 />
      </TechnologyIcon>
    </StyledRowContainer>
    <StyledRowContainer>
      <TechnologyIcon
        title="Gatsby"
        color="rgba(102, 51, 153)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <Gatsby />
      </TechnologyIcon>
      <TechnologyIcon
        title="Javascript"
        color="rgba(99, 168, 20)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <JsSquare />
      </TechnologyIcon>
      <TechnologyIcon
        title="Bootstrap"
        color="rgba(121, 82, 179)"
        setTitle={setTitle}
        setColor={setColor}
      >
        <Bootstrap />
      </TechnologyIcon>
    </StyledRowContainer>
  </StyledContainer>
);

export default TechnologyIcons;
