import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled, { keyframes } from "styled-components";
import BackgroundImage from "gatsby-background-image";
import cloud1 from "../../images/clouds/cloud1.png";
import cloud2 from "../../images/clouds/cloud2.png";
import cloud3 from "../../images/clouds/cloud3.png";
import cloud4 from "../../images/clouds/cloud4.png";
import cloud5 from "../../images/clouds/cloud5.png";

const movingCloud = keyframes`
{
  0%
  {
  opacity: 0;
  transform: scale(1);
  }
  25%, 75%{
    opacity: 1;
  }
  100%
  {
    opacity: 0;
    transform:scale(3);
  }
}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  position: absolute !important;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -10;
  background-position: 50% 50%;
`;

const CloudsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
`;

const Cloud = styled.img`
  position: absolute;
  bottom: -50px;
  max-width: 100%;
  animation: ${movingCloud} calc(5s * var(--somekeyword)) linear infinite;
  @media (max-width: 900px) {
    bottom: 0;
  }
`;

const Background = () => {
  const background = useStaticQuery(graphql`
    query {
      image_1920_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fixed(width: 1920, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_768_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const sources = [
    background.image_1920_width.childImageSharp.fixed,
    {
      ...background.image_768_width.childImageSharp.fixed,
      media: `(max-width: 768px) and (max-height: 512px)`,
    },
  ];
  return (
    <>
      <StyledBackgroundImage fixed={sources} />
      <CloudsContainer>
        <Cloud src={cloud1} style={{ "--somekeyword": "1" }} />
        <Cloud src={cloud2} style={{ "--somekeyword": "2" }} />
        <Cloud src={cloud3} style={{ "--somekeyword": "3" }} />
        <Cloud src={cloud4} style={{ "--somekeyword": "4" }} />
        <Cloud src={cloud5} style={{ "--somekeyword": "5" }} />
        <Cloud src={cloud1} style={{ "--somekeyword": "10" }} />
        <Cloud src={cloud2} style={{ "--somekeyword": "9" }} />
        <Cloud src={cloud3} style={{ "--somekeyword": "8" }} />
        <Cloud src={cloud4} style={{ "--somekeyword": "7" }} />
        <Cloud src={cloud5} style={{ "--somekeyword": "6" }} />
      </CloudsContainer>
    </>
  );
};

export default Background;
