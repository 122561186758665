import React from "react";
import styled from "styled-components";
import WebsiteCard from "../molecules/WebsiteCard";
import liveTODO from "../../images/LiveTODO.png";
import firmazdrewna from "../../images/FirmaZDrewna.png";
import szama from "../../images/Szama.png";
import goralPozwolenia from "../../images/GoralPozwolenia.png";

const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  > :nth-child(even) {
    @media (max-width: 1000px) {
      background-color: rgba(235, 235, 235);
    }
    .websiteContent {
      left: 0;
    }
    .contentTitle,
    .contentTechnologies,
    .contentDescription,
    .websiteContent,
    .link {
      text-align: left;
    }
    .imgTitleContainer {
      margin-left: auto;
      text-align: right;
    }
  }
`;

const WebsitesList = () => (
  <StyledContainer>
    <WebsiteCard
      src={liveTODO}
      alt="Live TODO"
      title="Live TODO"
      color="rgba(184, 136, 191)"
      link="https://livetodo.flisikowska.com"
      description="It is a fully responsive website that allows you create a simple list of tasks you can share with friends. Any change introduced to a list is propagated and visible instantly to all users currently watching the list."
      technologies="React | CSS | HTML | Javascript | WebSocket"
    />
    <WebsiteCard
      src={firmazdrewna}
      alt="Firma z drewna"
      color="rgba(132, 194, 37)"
      title="Firma z drewna"
      link="https://firmazdrewna.pl"
      description="It is a company website of the 'Firma z drewna'. It was tailor made on behalf of the client. Includes general
      informations about the company and presents products it offers. This website is fully responsive."
      technologies="Gatsby | CSS | HTML | Javascript"
    />
    <WebsiteCard
      src={szama}
      alt="Szama"
      color="rgba(147, 165, 207)"
      title="Szama"
      link="https://szama.flisikowska.com"
      description="It is an application designed to create a multi-day nutritional plan based on which it will generate a shopping list and calculate the number of calories and macronutrients for each day."
      technologies="React | CSS | HTML | Javascript"
    />
    <WebsiteCard
      src={goralPozwolenia}
      alt="Góral Pozwolenia"
      color="rgba(218,37,29)"
      title="Góral Pozwolenia"
      link="https://goralpozwolenia.pl"
      description="A company website of the 'Góral Pozwolenia'. It was tailor made on behalf of the client. Includes general
      informations about the company and services they offer. Website is fully responsive."
      technologies="WORDPRESS | CSS"
    />
  </StyledContainer>
);
export default WebsitesList;
