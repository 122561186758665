import React, { useEffect } from "react";
import styled from "styled-components";
import WebsiteContent from "./WebsiteContent";

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  margin: 40px auto;
  cursor: default;
  @media (min-width: 1400px) {
    width: 70%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0;
    margin: 0;
    padding: 70px 20px 35px 20px;
  }
`;

const TitleImageContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 50px 50px 50px;
  @media (max-width: 1000px) {
    margin: 0;
    width: 100%;
    text-align: center;
  }
`;

const StyledTitle = styled.h1`
  -webkit-text-stroke: 2px ${(props) => props.color};
  opacity: 0.4;
  z-index: -2;
  font-weight: 900;
  position: relative;
  top: 0.8vw;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-size: 4.2vw;
  font-family: "Bellota Text";
  color: ${({ theme }) => theme.white};
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledWebsiteImageContainer = styled.a`
  width: 100%;
  height: 100%;
  @media (min-width: 1001px) {
    overflow: hidden;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.2);
  }
`;

const WebsiteImage = styled.img`
  @media (min-width: 1001px) {
    transform: scale3d(1, 1, 1);
    :hover {
      transform: scale3d(1.01, 1.01, 1);
    }
  }
  transition: 0.5s;
  width: 100%;
  height: 100%;
`;

const WebsiteCard = ({
  src,
  color,
  alt,
  link,
  title,
  description,
  technologies,
}) => {
  useEffect(() => {
    const MoveContentUpDown = () => {
      const parallaxContents = document.getElementsByClassName(
        "vertical-parallax"
      );
      Array.from(parallaxContents).forEach((content) => {
        var viewportOffset = content.getBoundingClientRect();
        const bottomOffset = 0;
        const topOffset = 0;
        let percentageHeightPositionOnScreen =
          (viewportOffset.top -
            window.innerHeight * topOffset +
            content.offsetHeight * 0.5) /
          (window.innerHeight * (1 - topOffset - bottomOffset));
        let transform = `translateY(${percentageHeightPositionOnScreen * 40}%)`;
        content.style.transform = transform;
      });
    };

    if (window.innerWidth > 1000)
      document.addEventListener("scroll", () => {
        MoveContentUpDown();
      });

    document.addEventListener("resize", () => {
      if (window.innerWidth > 1000)
        document.addEventListener("scroll", () => {
          MoveContentUpDown();
        });
    });
  });

  return (
    <StyledContainer className="websiteContainer">
      <TitleImageContainer className="imgTitleContainer">
        <StyledTitle color={color}>{title}</StyledTitle>
        <StyledWebsiteImageContainer target="blank" href={link}>
          <WebsiteImage alt={alt} src={src} />
        </StyledWebsiteImageContainer>
      </TitleImageContainer>
      <WebsiteContent
        title={title}
        link={link}
        description={description}
        technologies={technologies}
        color={color}
      />
    </StyledContainer>
  );
};

export default WebsiteCard;
