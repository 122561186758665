import React from "react";
import styled, { keyframes } from "styled-components";

const moveArrow = keyframes`
{
	0%
	{
    transform: translate(0, -50%);
	}
  50%
  {
    transform: translate(3px, -50%);
  }
	100%
	{
    transform: translate(0, -50%);
	}
}
`;

const StyledButton = styled.a`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: ${(props) => props.color};
  font-family: "Unica One";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 22px;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 1px 1px 1px rgba(250, 250, 250);
  border-bottom: 3px solid ${(props) => props.color};
  > span {
    position: relative;
    :before {
      content: ">>";
      animation: ${moveArrow} 0.5s linear infinite;
      animation-play-state: paused;
      font-size: 15px;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      color: ${(props) => props.color};
    }
  }
  :hover {
    > span {
      :before {
        animation-play-state: running;
      }
    }
  }
  @media (max-width: 1000px) {
    border-bottom: none;
  }
`;

const Button = ({ text, href, color }) => (
  <StyledButton className="button" color={color} target="_blank" href={href}>
    <span>{text}</span>
  </StyledButton>
);

export default Button;
