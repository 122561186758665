import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";
import MainTemplate from "../templates/MainTemplate";
import Background from "../components/atoms/Background";
import AboutMeBox from "../components/molecules/AboutMeBox";
import WebsitesList from "../components/organisms/WebsitesList";
import SectionHeader from "../components/atoms/SectionHeader";
import MenuContainer from "../components/organisms/MenuContainer";
import MediaIcons from "../components/atoms/MediaIcons";
import StickyMediaIcons from "../components/atoms/StickyMediaIcons";
import ContactName from "../components/atoms/ContactName";
import Image from "../components/atoms/Image";
import photo from "../images/photo.jpg";

const HomeSection = styled.section`
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  grid-gap: 10vw;
  @media (max-width: 1500px) {
    grid-gap: 3vw;
  }

  @media (max-width: 1300px) {
    flex-flow: column nowrap;
    #aboutMeBox {
      margin: 100px 0 30px 0;
      width: 800px;
    }
  }
  @media (max-width: 900px) {
    padding: 0 50px;
    #aboutMeBox {
      width: 100%;
    }
  }
  @media (max-width: 550px) {
    padding: 0 20px;
  }
`;

const ContactSection = styled.section`
  width: 100%;
  height: 300px;
  text-align: center;
  margin-top: 180px;
  position: relative;
  background-color: rgba(238, 238, 238);
  :before {
    content: "";
    width: 50%;
    height: 100px;
    position: absolute;
    top: -48px;
    left: 0;
    background: rgba(238, 238, 238);
    transform: skewY(6deg);
    z-index: -10;
  }
  :after {
    content: "";
    width: 50%;
    height: 100px;
    position: absolute;
    top: -48px;
    right: 0;
    background: rgba(238, 238, 238);
    transform: skewY(-6deg);
    z-index: -10;
  }
`;

const Home = () => (
  <MainTemplate>
    <Background />
    <MenuContainer />
    <SEO title="Emilia Flisikowska" />
    <StickyMediaIcons />
    <HomeSection id="home">
      <AboutMeBox id="aboutMeBox" />
      <Image img={photo} alt="Emilia Flisikowska" />
    </HomeSection>
    <section id="portfolio">
      <SectionHeader title="Some of my work" />
      <WebsitesList />
    </section>
    <ContactSection id="contact">
      <ContactName />
      <MediaIcons />
    </ContactSection>
  </MainTemplate>
);

export default Home;
