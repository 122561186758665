import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Menu from "./Menu";
import Toggle from "../atoms/Toggle";

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  transition: all 0.5s ease;
  width: 100%;
  height: 80px;
  align-items: center;
  padding: 0px 30px 0 0;
  z-index: 101;
  @media (max-width: 1000px) {
    padding: 0;
    height: 60px;
    &.active {
      .toggle {
        backdrop-filter: blur(10px);
        display: none;
      }
      .toggleX {
        display: block;
      }
    }
  }
`;

const MenuContainer = () => {
  useEffect(() => {
    const header = document.getElementById("header");
    document.addEventListener("scroll", () => {
      const value = window.scrollY;
      header.style.backgroundColor =
        value > 5 && window.innerWidth > 1000
          ? "rgba(0, 0, 0, 0.5)"
          : "transparent";
      header.style.backdropFilter =
        value > 5 && window.innerWidth > 1000 ? "blur(5px)" : "blur(0px)";
    });
  });
  const [activeMenu, setActiveMenu] = useState(false);
  const toggle = () => {
    setActiveMenu((x) => !x);
  };
  return (
    <HeaderContainer className={activeMenu ? "active" : ""} id="header">
      <Menu callback={() => toggle()} activeMenu={activeMenu} />
      <Toggle callback={() => toggle()} />
    </HeaderContainer>
  );
};

export default MenuContainer;
