import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
  overflow: hidden;
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 10%;
  transform: rotate(45deg);
  margin: 0 20px;
  @media (max-width: 462px) {
    margin: 0 15px;
  }
  > svg {
    color: inherit;
    pointer-events: none;
    margin: 10px;
    transform: rotate(-45deg);
    transition: 0.5s ease;
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 7vw;
      height: 7vw;
    }
    @media (max-width: 390px) {
      width: 4vw;
      height: 4vw;
    }
  }
  :hover {
    > svg {
      color: ${({ theme }) => theme.white} !important;
    }
  }

  :after {
    content: "";
    display: block;
    z-index: -5;
    width: calc(100% + 1px);
    height: 100%;
    top: -100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 0.2s linear 0s;
    position: absolute;
    background-color: ${(props) => props.color};
  }

  :hover {
    :after {
      top: 0%;
      transition: all 0.2s linear 0s;
    }
  }
`;

const TechnologyIcon = ({ setTitle, setColor, title, color, children }) => (
  <IconWrapper
    onMouseEnter={() => {
      setTitle(title);
      setColor(color);
    }}
    onMouseOut={() => setTitle("")}
    color={color}
  >
    {children}
  </IconWrapper>
);

export default TechnologyIcon;
