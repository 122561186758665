import React, { useEffect } from "react";
import styled from "styled-components";
import { ExecuteWhen } from "../../helpers/functions";
import MenuElement from "../atoms/MenuElement";

const StyledWrapper = styled.ul`
  position: relative;
  display: flex;
  margin: 0;
  @media (max-width: 1000px) {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 60px 0px;
    height: 100vh;
    text-align: center;
    overflow: auto;
    background: ${({ theme }) => theme.black};
    opacity: 0;
    transform: translateX(100%);
    transition: 0.5s;
    &.active {
      transform: translateX(0);
      width: 100%;
      top: 0;
      height: 100vh;
      opacity: 1;
    }
  }
`;

const Menu = ({ activeMenu, callback }) => {
  const MenuOnScroll = () => {
    var html = document.getElementsByTagName("html")[0];
    var scrollDistance = html.scrollTop + window.innerHeight / 2;
    var sections = document.getElementsByTagName("section");
    var navElements = document.getElementsByClassName("navigation");
    Array.from(sections)
      .filter(
        (section) =>
          section.offsetTop <= scrollDistance ||
          section.offsetTop + section.offsetHeight <=
            html.scrollTop + window.innerHeight
      )
      .slice(-1)
      .forEach((section) => {
        Array.from(navElements).forEach((e) => {
          Array.from(e.childNodes)
            .filter((node) => node.tagName === "A")
            .forEach((node) => {
              node.classList.remove("active");
              if (node.hash === `#${section.id}`) {
                node.classList.add("active");
                if (window.location.hash !== node.hash)
                  window.history.replaceState(undefined, undefined, node.hash);
              }
            });
        });
      });
  };

  let delayedOnScrollTimeout = null;
  const onscroll = () => {
    if (delayedOnScrollTimeout) clearTimeout(delayedOnScrollTimeout);
    if (document.readyState !== "complete") return;
    delayedOnScrollTimeout = setTimeout(MenuOnScroll, 250);
  };

  useEffect(() => {
    document.addEventListener("scroll", onscroll);
    ExecuteWhen(onscroll, () => document.readyState === "complete");
  });

  return (
    <StyledWrapper className={activeMenu ? "active" : ""} id="menu">
      <MenuElement callback={callback} scrollTo="home" title="Home" />
      <MenuElement callback={callback} scrollTo="portfolio" title="Portfolio" />
      <MenuElement callback={callback} scrollTo="contact" title="Contact" />
    </StyledWrapper>
  );
};

export default Menu;
