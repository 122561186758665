import React, { useEffect } from "react";
import { Linkedin } from "@styled-icons/feather/Linkedin";
import { Facebook } from "@styled-icons/feather/Facebook";
import { Github } from "@styled-icons/feather/Github";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.4);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 100;
  @media (max-width: 900px) {
    flex-direction: row;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
  }
`;

const IconWrapper = styled.a`
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 10px;
  margin: 8px;
  transition: 0.4s ease;
  > svg {
    transition: 0.5s ease;
    margin: 10px;
    color: ${({ theme }) => theme.white};
  }
  :hover {
    border-color: ${({ theme }) => theme.primary};
    svg {
      color: ${({ theme }) => theme.primary};
    }
  }
`;
const StickyMediaIcons = () => {
  useEffect(() => {
    const HideStickyMediaIcons = () => {
      window.addEventListener("scroll", function (ev) {
        const stickyMediaIcons = document.getElementById("stickyMediaIcons");
        const stickyMediaIconsDistanceFromTop = stickyMediaIcons.getBoundingClientRect()
          .top;
        const mediaIconsDistanceFromTop = document
          .getElementById("mediaIcons")
          .getBoundingClientRect().top;
        let opacity = Math.max(
          0,
          Math.min(
            1,
            (mediaIconsDistanceFromTop - stickyMediaIconsDistanceFromTop) / 300
          )
        );
        stickyMediaIcons.style.opacity = opacity;
        stickyMediaIcons.style.visibility =
          opacity > 0.1 ? "visible" : "hidden";
      });
    };

    if (window.innerWidth < 900) HideStickyMediaIcons();
  });

  return (
    <StyledWrapper id="stickyMediaIcons">
      <IconWrapper
        href="https://www.linkedin.com/in/flisikowska"
        target="_blank"
        title="Linkedin"
      >
        <Linkedin size="20" src={Linkedin} alt="Linkedin" />
      </IconWrapper>
      <IconWrapper
        href="https://www.facebook.com/emilia.flisikowska.1"
        target="_blank"
        title="Facebook"
      >
        <Facebook size="20" src={Facebook} alt="Facebook" />
      </IconWrapper>
      <IconWrapper
        href="https://github.com/flisikowska"
        target="_blank"
        title="Github"
      >
        <Github size="20" src={Github} alt="Github" />
      </IconWrapper>
    </StyledWrapper>
  );
};
export default StickyMediaIcons;
