import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

const fadeInRight = keyframes`
{
	0%
	{
		transform: translateX(150px);
	}
	100%
	{
		transform: translateX(0);
		opacity: 1;
	}
}
`;

const StyledLi = styled.li`
  position: relative;
  list-style: none;
  margin: 5px 10px;
  @media (min-width: 1001px) {
    opacity: 0;
    animation: ${fadeInRight} 0.5s linear forwards;
    &:nth-child(1) {
      animation-delay: 1s;
    }
    &:nth-child(2) {
      animation-delay: 1.25s;
    }
    &:nth-child(3) {
      animation-delay: 1.5s;
    }
    &:nth-child(4) {
      animation-delay: 1.75s;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  padding: 15px 5px;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 2.3px;
  font-family: "Unica One";
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.2s;
  text-decoration: none;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.primary};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
    @media (max-width: 1000px) {
      height: 3px;
    }
  }
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.primary};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
    @media (max-width: 1000px) {
      height: 3px;
    }
  }
  :hover:before,
  &.active:before {
    transform: scaleX(1);
    transform-origin: left;
  }
  :hover,
  &.active {
    color: ${({ theme }) => theme.white};
  }
  :hover::after,
  &.active:after {
    transform: scaleX(1);
    transform-origin: right;
  }
  @media (max-width: 1000px) {
    font-size: 1.5em;
    padding: 15px 0px;
    font-weight: 400;
    margin: 10px 0;
  }
  @media (max-width: 260px) {
    font-size: 1em;
  }
`;

const MenuElement = ({ scrollTo, title, callback }) => (
  <StyledLi className="navigation">
    <StyledLink
      to={`#${scrollTo}`}
      onClick={(e) => {
        callback();
        e.preventDefault();
        document.getElementById(scrollTo).scrollIntoView();
      }}
    >
      {title}
    </StyledLink>
  </StyledLi>
);

export default MenuElement;
