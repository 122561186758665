import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  width: 450px;
  height: 600px;
  margin: 50px 0 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: unset;
    height: unset;
  }
`;

const StyledImageBorder = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid ${({ theme }) => theme.primary};
  :before {
    content: "";
    z-index: -5;
    position: absolute;
    top: 50px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% - 100px);
    background: ${({ theme }) => theme.black};
    transition: 0.5s ease-in-out;
    transform: scaleY(1);
    transition-delay: 0.3s;
  }
  :hover:before {
    transform: scaleY(0);
  }
  :after {
    content: "";
    z-index: -5;
    position: absolute;
    left: 50px;
    top: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 100px);
    background: ${({ theme }) => theme.black};
    transition: 0.5s ease-in-out;
    transform: scaleX(1);
  }
  :hover:after {
    transform: scaleX(0);
  }
`;

const StyledImage = styled.img`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: relative;
  box-shadow: 25px 25px 35px rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

const Image = ({ img, alt }) => (
  <StyledWrapper>
    <StyledImageBorder />
    <StyledImage src={img} alt={alt} />
  </StyledWrapper>
);

export default Image;
