import React from "react";
import styled from "styled-components";
import MyButton from "../atoms/Button";
import { Link } from "@styled-icons/octicons/Link";

const StyledContainer = styled.div`
  overflow: none;
  z-index: 10;
  @media (max-width: 1000px) {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 20px 0;
  }
`;

const StyledContent = styled.div`
  position: absolute;
  right: 0;
  padding: 20px;
  margin-right: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  max-width: 400px;
  text-align: right;
  color: ${({ theme }) => theme.black};
  padding: 40px;
  box-shadow: -15px -15px 15px rgba(255, 255, 255, 0.1),
    10px 10px 15px rgba(0, 0, 0, 0.1),
    inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 5px 5px 5px rgba(0, 0, 0, 0.1);
  @media (max-width: 1000px) {
    position: unset;
    width: 100%;
    max-width: none;
    text-align: center !important;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
`;

const StyledTitle = styled.h2`
  font-size: 2vw;
  font-weight: 500;
  margin: 0;
  text-align: right;
  position: relative;
  font-family: "Unica One";
  color: ${(props) => props.color};
  @media (max-width: 1300px) {
    font-size: 3vw;
  }
  @media (max-width: 1000px) {
    font-size: 8vw;
    text-align: center !important;
  }
`;

const StyledLink = styled.a`
  font-weight: 400;
  margin: 0;
  font-size: 1vw;
  color: rgba(153, 153, 153);
  text-decoration: none;
  transition: all 0.5s;
  @media (max-width: 1300px) {
    font-size: 1.5vw;
  }
  @media (max-width: 1000px) {
    font-size: 3.5vw;
    position: relative;
    bottom: 10px;
  }
`;

const StyledDescription = styled.p`
  font-size: 0.8em;
  font-weight: 400;
  position: relative;
  text-align: right;
  @media (max-width: 1000px) {
    text-align: justify !important;
    text-align-last: center;
  }
  @media (max-width: 500px) {
    font-size: 0.7em;
  }
  > span {
    font-size: 0.7em;
    display: block;
    margin-top: 20px;
  }
`;

const WebsiteLinkIcon = styled.a`
  position: absolute;
  top: 6px;
  right: 0px;
  text-decoration: none;
  color: rgba(221, 221, 221);
  display: none;
  > svg {
    transition: 0.5s ease;
    transition-delay: 0.3s;
  }
  @media (max-width: 1000px) {
    display: block;
  }
`;

const WebsiteContent = ({ color, title, link, description, technologies }) => {
  return (
    <StyledContainer>
      <StyledContent color={color} className="websiteContent vertical-parallax">
        <StyledTitle className="contentTitle" color={color}>
          {title}
        </StyledTitle>
        <StyledLink className="link" href={link} target="_blank">
          {link}
        </StyledLink>
        <StyledDescription className="contentDescription">
          {description}
          <span>{technologies}</span>
        </StyledDescription>
        <WebsiteLinkIcon href={link} target="_blank">
          <Link size="40" />
        </WebsiteLinkIcon>
        <MyButton color={color} href={link} text="Visit site" />
      </StyledContent>
    </StyledContainer>
  );
};
export default WebsiteContent;
