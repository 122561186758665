import React from "react";
import styled from "styled-components";

const StyledSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  font-family: "Unica One";
  font-size: 7rem;
  font-weight: 200;
  text-align: center;
  cursor: default;
  color: ${({ theme }) => theme.black};
  :before {
    content: "";
    flex: 1 1;
    margin: auto;
    margin-right: 10px;
    border-bottom: 3px solid rgba(85, 85, 85);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
  :after {
    content: "";
    flex: 1 1;
    margin: auto;
    margin-left: 10px;
    border-bottom: 3px solid rgba(85, 85, 85);
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }
  @media (max-width: 920px) {
    font-size: 10vw;
    :before {
      border-bottom: 2px solid rgba(85, 85, 85);
    }
    :after {
      border-bottom: 2px solid rgba(85, 85, 85);
    }
  }

  @media (max-width: 1000px) {
    margin-top: 15px;
  }
`;

const SectionHeader = ({ title }) => (
  <StyledSectionHeader>{title}</StyledSectionHeader>
);

export default SectionHeader;
