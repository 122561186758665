export const ExecuteWhen = (
  func,
  checkConditionFunc,
  poolingInterval = 100
) => {
  let interval = setInterval(() => {
    if (checkConditionFunc()) {
      clearInterval(interval)
      func()
    }
  }, poolingInterval)
}

export const isInViewport = element => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    // rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const isHigherThanHalfOfTheScreen = element => {
  const rect = element.getBoundingClientRect()
  // const buffer = window.innerHeight / 10
  return (
    // rect.top - window.innerHeight / 2 < buffer ||
    // rect.bottom - window.innerHeight / 2 < buffer
    rect.top < window.innerHeight / 2 && rect.top >= 0
    // (rect.bottom > window.innerHeight / 2 && rect.bottom <= window.innerHeight)
  )
}
